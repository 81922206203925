import { createSelector } from '@ngrx/store'
import { exhaustiveCheck } from 'app/shared/utils/exhautisveCheck'
import moment, { Moment } from 'moment'
import { getUserCompanyName } from '../companies/companies.selectors'
import { getUnits } from '../fields/fields.selectors'
import { getJobsite } from '../jobsite'
import { getJobsiteId, getTechnique } from '../router/router.selectors'
import { AggregationType } from '../../../shared/constants/aggregation-type.enum'
import { Metric } from '../../../shared/constants/metric.enum'
import {
  getColumnsWithStats,
  getParametersLoaded,
} from '../../../jobsites-management/jobsite-summary/store/columns-stats/columns-stats.selectors'
import { ProgressCategory } from '../../../jobsites-management/jobsite-summary/models/progress/progress-category.enum'
import { ProgressType } from '../../../jobsites-management/jobsite-summary/models/progress/progress-type.enum'
import { ProgressDto } from '../../../jobsites-management/jobsite-summary/models/progress/progress.model'
import { getState, State } from '../state'
import { ChartPeriod } from '../../../jobsites-management/jobsite-summary/jobsite-productivity/models/productivity.model'
import { TechniqueNames } from '../../../shared/remote-services/dtos/technique.dto'
import { MsTimestamp } from '../../../jobsites-management/jobsite-summary/jobsite-productivity/services/series/piles-productivity-series.service'

export const getProgressData = createSelector(
  getState,
  (state: State) => state.progressData.value,
)

export const getProgressDataLoaded = createSelector(
  getState,
  state => state.progressData.loaded,
)

const getProgressByType = (
  data: ProgressDto[],
  types: string[],
): ProgressDto[] =>
  data
    .filter(el => types.includes(el.type))
    .sort((a, b) => a.date.valueOf() - b.date.valueOf())

const getDataByType = (
  data: ProgressDto[],
  type: ProgressType,
  category?: ProgressCategory,
): (readonly [Moment, number])[] => {
  return getProgressByType(data, [type])
    .filter(progress =>
      category
        ? progress.category == null || progress.category === category
        : true,
    )
    .map(el => [el.date, el.count] as const)
}

export const getProgressDataByType = (type: ProgressType) => {
  return createSelector(
    getProgressData,
    getProgressPeriod,
    (data, period): [MsTimestamp, number][] => {
      const dataByType = getDataByType(data || [], type)
      return reduceProgressData(dataByType, period)
    },
  )
}

export const getProgressDataByTypeAndCategory = (type: ProgressType) => {
  return createSelector(
    getProgressData,
    getProgressPeriod,
    getProgressCategory,
    (
      data: ProgressDto[],
      period: ChartPeriod,
      category: ProgressCategory,
    ): [number, number][] => {
      const dataByTypeAndCategory = getDataByType(data || [], type, category)
      return reduceProgressData(dataByTypeAndCategory, period)
    },
  )
}

const reduceProgressData = (
  dataByType: (readonly [Moment, number])[],
  period: ChartPeriod,
): [MsTimestamp, number][] => {
  if (period === 'DAILY') {
    return dataByType.map(([date, count]) => [
      date.valueOf() as MsTimestamp,
      count,
    ])
  } else if (period === 'MONTHLY' || period === 'WEEKLY') {
    return Object.entries(
      dataByType.reduce(
        (record, [date, count]) => {
          // tuple can't be used as key of map, gen a string key based on tuple [year, month] or [year, week]
          const key = `${date.year()}-${
            period === 'MONTHLY' ? date.month() : date.isoWeek()
          }`
          if (Object.prototype.hasOwnProperty.call(record, key)) {
            record[key].acc += count
          } else {
            record[key] = {
              from: moment(date).startOf(
                period === 'MONTHLY' ? 'month' : 'isoWeek',
              ),
              to: moment(date).endOf(
                period === 'MONTHLY' ? 'month' : 'isoWeek',
              ),
              acc: count,
            }
          }
          return record
        },
        {} as Record<
          string,
          {
            from: Moment
            to: Moment
            acc: number
          }
        >,
      ),
    ).map(([_, obj]) => [obj.from.valueOf() as MsTimestamp, obj.acc])
  }
  exhaustiveCheck(period)
}

export const getProgressCategory = createSelector(
  getJobsite,
  getTechnique,
  (jobsite, technique): ProgressCategory => {
    if (jobsite) {
      const progressCategory = jobsite.techniques.find(
        t => t.name === technique,
      ).progressCategory
      if (
        progressCategory &&
        [
          ProgressCategory.LENGTH,
          ProgressCategory.COUNT,
          ProgressCategory.AREA_SIMPLE,
        ].includes(progressCategory)
      ) {
        return progressCategory
      }
    }
  },
)

export const getProgressCategoriesByTechnique = createSelector(
  getTechnique,
  (technique: TechniqueNames): ProgressCategory[] =>
    technique === 'HF'
      ? [ProgressCategory.COUNT, ProgressCategory.AREA_SIMPLE]
      : [ProgressCategory.COUNT, ProgressCategory.LENGTH],
)

export const getProgressPeriod = createSelector(
  getState,
  state => state.progressPeriod,
)

export const getPlannedProgress = createSelector(
  getProgressData,
  (data): ProgressDto[] => {
    if (data) {
      return getProgressByType(data, [
        ProgressType.PLANNED,
        ProgressType.PLANNED_CUSTOMER,
      ])
    } else {
      return []
    }
  },
)

export const getXAxis = createSelector(
  getUserCompanyName,
  (company): readonly [Metric, AggregationType] => {
    if (company === 'BSL') {
      return [Metric.Depth, AggregationType.MAX]
    }
    return [Metric.Flowrate, AggregationType.AVG]
  },
)

export const getYAxis = createSelector(
  getUserCompanyName,
  (company): readonly [Metric, AggregationType] => {
    if (company === 'BSL') {
      return [Metric.Flowrate, AggregationType.AVG]
    }
    return [Metric.HydraulicPressure, AggregationType.AVG]
  },
)

export const getPumpDataLoaded = createSelector(
  getJobsiteId,
  getTechnique,
  getParametersLoaded,
  getXAxis,
  getYAxis,
  (jobsiteId, technique, parameterMetric, xAxis, yAxis): boolean => {
    return (
      parameterMetric[jobsiteId] &&
      parameterMetric[jobsiteId][xAxis[0] + technique] &&
      parameterMetric[jobsiteId][xAxis[0] + technique].includes(xAxis[1]) &&
      parameterMetric[jobsiteId][yAxis[0] + technique] &&
      parameterMetric[jobsiteId][yAxis[0] + technique].includes(yAxis[1])
    )
  },
)

export const getXAxisUnit = createSelector(getXAxis, getUnits, (xAxis, units) =>
  units ? units[xAxis[0]] : undefined,
)

export const getYAxisUnit = createSelector(getYAxis, getUnits, (yAxis, units) =>
  units ? units[yAxis[0]] : undefined,
)

export const getPumpChartData = createSelector(
  getTechnique,
  getPumpDataLoaded,
  getXAxis,
  getYAxis,
  getColumnsWithStats,
  (technique, pumpDataLoaded, xAxis, yAxis, columns) => {
    if (!columns || !pumpDataLoaded) {
      return []
    }

    return columns
      .filter(c => c.stats)
      .map(column => ({
        id: column.key.name,
        x: column.stats[xAxis[0] + technique]
          ? column.stats[xAxis[0] + technique][xAxis[1]]
          : [],
        y: column.stats[yAxis[0] + technique]
          ? column.stats[yAxis[0] + technique][yAxis[1]]
          : [],
      }))
  },
)
