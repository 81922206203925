import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import moment from 'moment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ProgressCategory } from '../../jobsites-management/jobsite-summary/models/progress/progress-category.enum'
import { ProgressType } from '../../jobsites-management/jobsite-summary/models/progress/progress-type.enum'
import {
  GenericProgressDto,
  ProgressDto,
} from '../../jobsites-management/jobsite-summary/models/progress/progress.model'

@Injectable()
export class ProgressService {
  constructor(private http: HttpClient) {}

  private baseUrl: (_: string) => string = (technique: string) =>
    `/rest/${technique}/dashboard/progress`

  public getProgression(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    columnDoneIds: string[],
  ): Observable<ProgressDto[]> {
    return this.http
      .post<ProgressDto[]>(
        `${this.baseUrl(techniqueName)}/${jobsiteId}/all`,
        columnDoneIds,
      )
      .pipe(
        map(result =>
          result.map(progress => ({
            ...progress,
            date: moment(progress.date, 'YYYY-MM-DD'),
          })),
        ),
      )
  }

  public getPilingProductivityDashboardProgress(
    jobsiteId: string,
    columnDoneIds: string[],
  ): Observable<ProgressDto[]> {
    return this.http
      .post<ProgressDto[]>(
        `${this.baseUrl('PILES')}/productivity/${jobsiteId}`,
        columnDoneIds,
      )
      .pipe(
        map((result: ProgressDto[]): ProgressDto[] =>
          result.map(
            (progress: ProgressDto): ProgressDto => ({
              ...progress,
              date: moment(progress.date, 'YYYY-MM-DD'),
            }),
          ),
        ),
      )
  }

  public batchSavePlannedProgression(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    plannedProgression: ProgressDto[],
    type: ProgressType,
    category: ProgressCategory,
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseUrl(techniqueName)}/batch/${jobsiteId}/${type}/${category}`,
      plannedProgression.map(v => this.formatDate(v)),
    )
  }

  updatePlannedProgress(
    technique: string,
    jobsiteId: string,
    progress: ProgressDto,
  ): Observable<void> {
    return this.http.put<void>(
      `${this.baseUrl(technique)}/${jobsiteId}/edit`,
      this.formatDate(progress),
    )
  }

  addPlannedProgress(
    technique: string,
    jobsiteId: string,
    progress: ProgressDto,
  ): Observable<string> {
    return this.http.post<string>(
      `${this.baseUrl(technique)}/${jobsiteId}/add`,
      this.formatDate(progress),
    )
  }

  deletePlannedProgress(technique: string, id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl(technique)}/delete/${id}`)
  }

  updateProgressCategory(
    jobsiteId: string,
    techniqueName: TechniqueNames,
    category: ProgressCategory,
  ): Observable<{
    progressCategory: ProgressCategory
    jobsiteId: string
    techniqueName: TechniqueNames
  }> {
    return this.http
      .post(
        `${this.baseUrl(techniqueName)}/${jobsiteId}/category/${category}`,
        undefined,
      )
      .pipe(
        map(_ => ({ progressCategory: category, jobsiteId, techniqueName })),
      )
  }

  private formatDate(progress: ProgressDto): GenericProgressDto<string> {
    return { ...progress, date: progress.date.format('YYYY-MM-DD') }
  }
}
