// Build information, automatically generated by `ng-info`
const build = {
    version: "21.4.0-SNAPSHOT",
    timestamp: "Fri Dec 13 2024 16:13:31 GMT+0100 (Central European Standard Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "a92799",
        fullHash: "a92799cf4a291b091cd80ee4006f84adaccc244f"
    }
};

export default build;